<script>
//import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
//import flatPickr from "vue-flatpickr-component";
//import "flatpickr/dist/flatpickr.css";
import PageHeader from "@/components/page-header";
import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";

import { required, email, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { mapState } from "vuex";
import axios from 'axios';
import Paginate from 'vuejs-paginate-next';

import {
    authMethods,
    authFackMethods,
    notificationMethods,
} from "@/state/helpers";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    page: {
        title: "Profiles",
        meta: [{
            name: "description",
            content: appConfig.description
        }],
    },
    data() {
        return {
            title: "Profiles",
            items: [{
                text: "Profiles",
                href: "/profiles",
            },
            {
                text: "Profiles",
                active: true,
            },
            ],
            value: ['javascript'],
            file: "",
            imgsrc: "",
            date: null,

            submitted: false,
            regError: null,
            regSuccess: null,
            tryingToRegister: false,
            isRegisterError: false,
            registerSuccess: false,
            userserror: false,
            userserrormsg: '',
            usersok: false,
            is_admin: false,
            usersokmsg: '',
            userSearchTerm: '',
            currentpage: 0,
            totalpage: 0,
            totalrows: 0,

            updateuser_id: 0,
            usertype: 'user',
            userstatus: '',
            userslist: [],
            passerror: false,
            passok: false,
            passerrormsg: '',
            passokmsg: '',
            username: '',
            email: '',
            type: 'Mobile',
            status: 'Active',
            isadd: true,
            modaltitle: '',
            buttonname: '',
            password: '',
            cpassword: '',
            full_name: '',
            short_name: '',
            contact: '',
            url: '',

        };
    },
    validations: {
        user: {
            username: {
                required: helpers.withMessage("User name is required", required),
            },

            email: {
                required: helpers.withMessage("Email is required", required),
                email: helpers.withMessage("Please enter valid email", email),
            }
        },
    },
    computed: {
        ...mapState("authfack", ["status"]),
        notification() {
            return this.$store ? this.$store.state.notification : null;
        }
    },
    components: {
        Layout,
        paginate: Paginate,
        PageHeader,
        //Multiselect,
        //flatPickr
    }, mounted: function () {
        this.getUserData();
    },
    methods: {
        clickCallback: function (pageNum) {
            // alert("pageNum==" + pageNum);
            this.currentpage = parseInt(pageNum) - 1;
            this.getUserData();

        },
        async getUserData() {

            var result = await axios.post(appConfig.api_url + 'profile/getall', {
                filter: '' + this.userSearchTerm,
                page: this.currentpage
            })

            var users = result.data.data.user_res;
            this.totalpage = result.data.totalpages;
            this.totalrows = result.data.totalrows;

            this.userslist = users;

        },
        ...authMethods,
        ...authFackMethods,
        ...notificationMethods,
        async updateuserprofile() {
            this.submitted = true;
            // stop here if form is invalid

            this.tryingToRegister = true;
            this.regError = null;
            var goto = 'profile/update';
            if (this.isadd) {
                goto = 'profile/create';

                if (this.password !== this.cpassword) {
                    // alert('Password mismatched');
                    this.regError = 'Password mismatched';
                    this.isRegisterError = true;
                    return;
                }

            }

            // alert(appConfig.api_url + '' + goto);

            const result = await axios.post(appConfig.api_url + '' + goto, {
                id: this.updateuser_id,
                full_name: this.full_name,
                short_name: this.short_name,
                type: this.type,
                status: this.status,
                url: this.url,
                contact: this.contact
            });

            //  alert(JSON.stringify(result.data))

            if (result.data.status == 'errors') {
                this.tryingToRegister = false;
                this.regError = result.data.data.data;
                this.isRegisterError = true;
                // return this.authError = result.data.data.data;
            } else {
                this.tryingToRegister = false;
                this.isRegisterError = false;
                this.registerSuccess = true;
                this.regSuccess = result.data.data.data;
                this.getUserData();
                document.getElementById('closeupdatemodalbutton').click();
                // localStorage.setItem('username', this.user.name)
                // localStorage.setItem('useremail', this.user.useremail)
                // //   alert(result.data.data.data);
                //  console.log(result.data.data.data);
            }

        }, async filteruser() {
            this.userslist = [];
            this.getUserData();
        }, updateuser(id, full_name, short_name, type, status, url, contact) {
            this.modaltitle = 'Update profile';
            this.buttonname = 'Update';
            this.isadd = false;
            this.updateuser_id = id;
            this.full_name = full_name;
            this.short_name = short_name;
            this.type = type;
            this.status = status;
            this.url = url;
            this.contact = contact;

            document.getElementById('openupdatemodalbutton').click();
            this.isRegisterError = false;
            this.registerSuccess = false;

        }, createuser() {
            this.isRegisterError = false;
            this.registerSuccess = false;
            this.modaltitle = 'Create new profile';
            this.buttonname = 'Add';
            this.isadd = true;
            this.updateuser_id = '-1';
            this.full_name = '';
            this.short_name = '';
            this.type = 'Mobile';
            this.status = 'Active';
            this.url = '';
            this.contact = '';
            document.getElementById('openupdatemodalbutton').click();
        }, async deleteprofile(id) {

            let text = "Do you want to DELETE Profile?";
            if (confirm(text) == true) {
                await axios.post(appConfig.api_url + 'DeleteProfile/' + id)
                this.getUserData();
                this.registerSuccess = true;
                this.regSuccess = 'Profile Deleted'
                
            }
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row mb-3" style="background-color: white">
            <!-- <h3 class="m-2">Profiles</h3> -->
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <div class="form-icon m-2">
                    <input v-model="userSearchTerm" type="text" @keyup="filteruser" placeholder="Search by all"
                        class="form-control form-control-icon" aria-label="Recipient's username"
                        aria-describedby="button-addon2">
                    <i class=" ri-search-line"></i>
                </div>
                <div class="page-title-right m-2">
                    <button class="btn btn-success " style="float:right" @click="createuser">+ Create Profile</button>
                </div>
            </div>
        </div>

        <form class="needs-validation" @submit.prevent="updateuserprofile" enctype="multipart/form-data">
            <div class="row">
                <div class="card ">


                    <div class="card-body m-2">
                        <div class="col-12" style="padding-bottom:10px">
                            <b-alert v-model="registerSuccess" style="padding-bottom:10px" variant="success"
                                dismissible>
                                {{ regSuccess }}
                            </b-alert>
                        </div>

                        <div class="table-responsive table-card">
                            <table class="table align-middle table-nowrap mb-0">
                                <thead class="table-light">
                                    <tr>
                                        <th scope="col">Action</th>

                                        <th scope="col">ID</th>
                                        <th scope="col">Code for Writers </th>
                                        <th scope="col">Full name</th>
                                        <th scope="col">Short name</th>
                                        <th scope="col">URL</th>
                                        <th scope="col">Contact</th>
                                        <th scope="col">Type</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Create At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(user) of userslist" v-bind:key="user.id"
                                        :style="user.status == 'Active' ? 'background-color:#ddfcdd' : 'background-color:#ffe5e5'">
                                        <td><a style="padding-right:10px"
                                                @click="updateuser(user.id, user.full_name, user.short_name, user.type, user.status, user.url, user.contact)"><i
                                                    class="ri-edit-2-fill"></i></a>
                                                    <a @click="deleteprofile(user.id)"><i class=" ri-close-circle-fill"></i></a>
                                        </td>
                                        <td>{{ user.id }}</td>
                                        <td>{{ user.id }}-{{ user.short_name.toUpperCase() }}-{{ user.type.substring(0, 1).toUpperCase() }}
                                        </td>
                                        <td>{{ user.full_name }}</td>
                                        <td>{{ user.short_name }}</td>
                                        <td v-if="user.url !== ''"><a :href="user.url" target="_blank">View Link</a>
                                        </td>
                                        <td v-if="user.url == ''"> </td>
                                        <td>{{ user.contact }}</td>
                                        <td>{{ user.type }}</td>
                                        <td>{{ user.status }}</td>
                                        <td>{{ user.customecreatedat }}</td>

                                    </tr>

                                </tbody>

                            </table>
                            <!-- end table -->
                        </div>

                        <div class="mt-4">
                            <div class="col-12">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">

                                    <p style="float:left">Rows: {{ totalrows }}</p>

                                    <div class="page-title-right" style="float:right">
                                        <paginate v-model="page" :page-count="totalpage" :margin-pages="2"
                                            :click-handler="clickCallback" :prev-text="'Prev'" :next-text="'Next'"
                                            :container-class="'pagination'" :page-class="'page-item'">
                                        </paginate>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <!--end tab-pane-->


                    </div>
                </div>
                <!--end col-->
            </div>
        </form>
        <div class="row">

        </div>

        <!-- start of create document edit modal -->
        <a data-bs-toggle="modal" id="openupdatemodalbutton" data-bs-target="#updatecreatedocumentsmodal"></a>
        <div class="modal fade" id="updatecreatedocumentsmodal" tabindex="-1" aria-labelledby="exampleModalgridLabel">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalheader">{{ modaltitle }}</h5>
                        <button type="button" id="closeupdatemodalbutton" class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                        <b-alert v-model="isRegisterError" class="mt-3" variant="danger" dismissible>{{ regError }}
                        </b-alert>
                        <form @submit.prevent="updateuserprofile" id="updateDocumentform">
                            <div class="row g-3">
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label for="phonenumberInput" class="form-label">Full Name</label>
                                        <input required type="text" class="form-control" id="mobile" v-model="full_name"
                                            placeholder="Enter full name" />
                                    </div>
                                </div>
                                <!--end col-->
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label for="emailInput" class="form-label">Short Name</label>
                                        <input type="text" class="form-control" v-model="short_name"
                                            placeholder="Enter short name" required />

                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label for="phonenumberInput" class="form-label">URL</label>
                                        <input type="url" class="form-control" id="mobile" v-model="url"
                                            placeholder="Enter profile url" />
                                    </div>
                                </div>
                                <!--end col-->
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label for="emailInput" class="form-label">Contact / Whatsapp</label>
                                        <input type="text" class="form-control" v-model="contact"
                                            placeholder="Enter your email" />

                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <label for="firstName" class="form-label">Select Type</label>
                                    <select required v-model="type" class="form-select mb-3"
                                        aria-label="Default select example">
                                        <option value="Mobile">Mobile</option>
                                        <option value="Upwork">Upwork</option>
                                        <option value="Fiver">Fiver</option>
                                        <option value="Website">Website</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>
                                <div class="col-lg-6">
                                    <div>
                                        <label for="firstName" class="form-label">Select Status</label>
                                        <select required v-model="status" class="form-select mb-3"
                                            aria-label="Default select example">
                                            <option value="Active">Active</option>
                                            <option value="Disabled">Disabled</option>
                                        </select>
                                        <input type="hidden" v-model="updateuser_id">

                                    </div>
                                </div>
                                <!--end col-->

                                <!--end col-->
                                <div class="col-lg-12">
                                    <div class="hstack gap-2 justify-content-end">
                                        <button type="button" class="btn btn-light"
                                            data-bs-dismiss="modal">Close</button>
                                        <button type="submit" class="btn btn-primary">{{ buttonname }}</button>
                                    </div>
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <!--end row-->
    </Layout>
</template>